import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function showSuccessMessage(message: string, options?: ToastOptions) {
  toast.success(message, {
    position: "bottom-right",
    theme: "colored",
    ...options,
  });
}
export function showErrorMessage(message: string, options?: ToastOptions) {
  toast.error(message, {
    position: "bottom-right",
    theme: "colored",
    ...options,
  });
}
