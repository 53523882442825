import { StylesConfig } from "react-select";

import { PRIMARY_COLORS } from "const";
//@ts-ignore
import resolveConfig from "tailwindcss/resolveConfig";
import { ISelectOption } from "types";

const defaultConfig = resolveConfig();

export function getStylesForReactSelectInputs(
  config: StylesConfig<ISelectOption> = {}
) {
  const customStyles: StylesConfig<ISelectOption> = {
    valueContainer: (provided) => ({ ...provided, flexWrap: "wrap" }),
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        color: "black",
        fontSize: defaultConfig.theme.fontSize.xs,
        width: "15px",
      };
    },
    indicatorSeparator: () => ({}),
    placeholder: (provided, state) => ({
      ...provided,
      color: "black",
      fontSize: defaultConfig.theme.fontSize.sm,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: PRIMARY_COLORS.GHOST_WHITE,
      paddingRight: "5px",
      borderColor: state.isFocused ? "#00649c" : "#E6EFF5",
      ":hover": {
        borderColor: "#00649c",
      },
    }),
    option: (provided, state) => ({ display: "flex", alignItems: "center" }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: "8px",
      paddingBottom: "12px",
      paddingLeft: "24px",
      paddingRight: "24px",
    }),
    ...config,
  };
  return customStyles;
}

export function getGradient(ctx: any, chartArea: any) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "#6B30FF");
    gradient.addColorStop(1, "#0FBCB8");
  }

  return gradient;
}
