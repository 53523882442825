import React, { memo } from "react";
import usePresignedUrl from "hooks/usePresignedUrl";

export interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  placeholder?: string;
  src?: string | undefined;
}

function SignedImg({ alt, crossOrigin, src, placeholder, ...rest }: IProps) {
  const { imageUrl, loading } = usePresignedUrl(src);

  return (
    <img
      alt={alt || ""}
      src={loading ? placeholder || "" : imageUrl || placeholder || ""}
      crossOrigin={crossOrigin || ""}
      onError={(e) => {
        e.currentTarget.src = placeholder || "";
      }}
      {...rest}
    />
  );
}

export default memo(SignedImg);
