import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import { useApolloClientProvider } from "hooks/auth";
import { ConfirmationModalProvider } from "utils/confirmationModalContext";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "assets/styles/index.css";
import config from "config";
import { Amplify } from "aws-amplify";
import { setupAmplifyAnalytics } from "services/analytics";

import Hotjar from "@hotjar/browser";

Amplify.configure({
  Auth: {
    region: config.REGION,
    identityPoolId: config.IDENTITY_POOL_ID,
    userPoolId:
      process.env.REACT_APP_ENV === "dev"
        ? config.USER_POOL_ID_DEV
        : config.USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_ENV === "dev"
        ? config.WEB_CLIENT_ID_DEV
        : config.WEB_CLIENT_ID,
  },
  Logging: {
    logGroupName: config.CLOUD_WATCH_LOG_GROUP,
    logStreamName: config.CLOUD_WATCH_LOG_STREAM,
    region: config.REGION,
  },
});
setupAmplifyAnalytics();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const siteId = process.env.REACT_APP_ENV === "dev" ? 5047835 : 5053258;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function Render() {
  const { client } = useApolloClientProvider();
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ConfirmationModalProvider>
          <App />
        </ConfirmationModalProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

root.render(<Render />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
