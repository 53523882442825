import { gql, useQuery } from "@apollo/client";


export const usePresignedUrl = (
  url: string | null | undefined,
)=> {
  const { data, loading } = useQuery(gql`
    query preSignedUrl($objectKey: String!) {
      preSignedUrl(objectKey: $objectKey)
    }
  `, {
    variables: {
      objectKey: url
    },
    skip: !url,
  });

  return {
    imageUrl: data?.preSignedUrl,
    loading,
  };
}

export default usePresignedUrl;