import { APP_ROUTES } from "const";
import { memo, ReactNode } from "react";
import notFoundImg from "assets/images/404.svg";

interface IError {
  error?: any;
  children?: ReactNode;
}

function ErrorFallback({ error, children }: IError) {
  return (
    <>
      {error.response?.status !== 401 && (
        <div className="flex flex-col w-full h-full flex-wrap content-center justify-around">
          <div className="flex justify-around flex-wrap align-center content-center justify-center max-w-[600px] p-[60px]">
            <img
              src={notFoundImg}
              alt="notFoundImg"
              className="flex mb-[12px]"
            />
            <div className="flex text-primary text-[20px] font-bold pb-[8px] w-[100%] justify-center">
              Something went wrong
            </div>
            <div className="text-[#98A0A6] text-[14px] w-[100%] justify-center align-center text-center">
              {!children && `Error: ${error.message}`}
              {children}
            </div>
            <a
              href={APP_ROUTES.DASHBOARD}
              className="no-underline flex flex-wrap bg-[#00649c] rounded-[4px] w-[160px] h-[40px] text-white text-[12px] align-center content-center justify-center mt-[24px]"
            >
              Go to my feed
            </a>
          </div>
        </div>
      )}
    </>
  );
}
export default memo(ErrorFallback);
