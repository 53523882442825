export function buildOptionsFromList(list: string[]) {
  return list.map((item) => ({ name: item, value: item }));
}
export function buildOptionsWithLabel(list: string[]) {
  return list.map((item) => ({ label: item, value: item }));
}

export function getIndexByValue(object: { [key: string]: any }, value: string) {
  // @ts-ignore
  const obj = object.find((key) => key.column === value);
  return object.indexOf(obj);
}

export function preventEnterSubmit(keyEvent) {
  if (
    (keyEvent.charCode || keyEvent.keyCode) === 13 &&
    keyEvent.target.localName !== "textarea"
  ) {
    keyEvent.preventDefault();
  }
}
