import appRoutes from "const/appRoutes";
import { ENTITY_TYPE, IEntity, IEntityTypeProfile } from "types";

export function addHttps(url?: string) {
  return url?.match("http") ? url : `https://${url}`;
}
export function removeHttps(url: string) {
  return url.replace("http://", "").replace("https://", "").replace(/\/+$/, "");
}

export const getLinkByEntity = (entity: IEntity) => {
  if (entity.__typename === "Entity") {
    const tProfile = entity?.verticalProfiles[0]?.entityTypeProfiles[0];

    if (tProfile?.entityType === ENTITY_TYPE.ORGANIZATION) {
      return appRoutes.COMPANY_PROFILE.replace(":entityId", entity.id);
    } else if (tProfile?.entityType === ENTITY_TYPE.UNIVERSITY) {
      return appRoutes.UNIVERSITY_PROFILE.replace(":entityId", entity.id);
    } else if (tProfile?.entityType === ENTITY_TYPE.INVESTOR) {
      return appRoutes.INVESTOR_PROFILE.replace(":entityId", entity.id);
    } else if (tProfile?.entityType === ENTITY_TYPE.GOVERNMENT_AGENCY) {
      return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(
        ":entityId",
        entity.id
      );
    } else if (tProfile?.entityType === ENTITY_TYPE.NATIONAL_LAB) {
      return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(
        ":entityId",
        entity.id
      );
    } else if (tProfile?.entityType === ENTITY_TYPE.CONSORTIUM) {
      return appRoutes.CONSORTIUM_PROFILE.replace(":entityId", entity.id);
    } else if (tProfile?.entityType === ENTITY_TYPE.BRAND) {
      return appRoutes.ENTERPRISE_USERS_PROFILE.replace(":entityId", entity.id);
    } else if (tProfile?.entityType === ENTITY_TYPE.CENTER) {
      return appRoutes.GROUPS_AND_CENTERS_PROFILE.replace(
        ":entityId",
        entity.id
      );
    }
  } else if (entity.__typename === "FundingRound") {
    return appRoutes.FUNDING_ROUNDS;
  } else if (entity.__typename === "InvestmentRound") {
    return appRoutes.FUNDING_ROUNDS;
  } else if (entity.__typename === "PrimaryClassification") {
    return appRoutes.COMPANIES + "?primaryClassification=" + entity.id;
  } else if (entity.__typename === "SecondaryClassification") {
    return appRoutes.COMPANIES + "?secondaryClassification=" + entity.id;
  }

  return "/";
};

export const getLinkByEntityTypeProfile = (
  tProfile: IEntityTypeProfile,
  entityId: string
) => {
  if (tProfile?.entityType === ENTITY_TYPE.ORGANIZATION) {
    return appRoutes.COMPANY_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.UNIVERSITY) {
    return appRoutes.UNIVERSITY_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.INVESTOR) {
    return appRoutes.INVESTOR_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.GOVERNMENT_AGENCY) {
    return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.NATIONAL_LAB) {
    return appRoutes.GOVERNMENT_ENTITIES_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.CONSORTIUM) {
    return appRoutes.CONSORTIUM_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.BRAND) {
    return appRoutes.ENTERPRISE_USERS_PROFILE.replace(":entityId", entityId);
  } else if (tProfile?.entityType === ENTITY_TYPE.CENTER) {
    return appRoutes.GROUPS_AND_CENTERS_PROFILE.replace(":entityId", entityId);
  }
  return "/";
};
