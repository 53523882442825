import config from "config";

export const ACCESS_LEVEL = {
  USER: config.VERTICAL + "_User",
  PREMIUM: config.VERTICAL + "_Premium",
  SUBSCRIPTION_ADMIN: config.VERTICAL + "_SubAdmin",
  ADMIN: config.VERTICAL + "_Admin",
  SUPER_ADMIN: config.VERTICAL + "_Super",
};

export const USER_ROLES_BY_ACCESS_LEVEL = {
  [ACCESS_LEVEL.USER]: 0,
  [ACCESS_LEVEL.PREMIUM]: 1,
  [ACCESS_LEVEL.SUBSCRIPTION_ADMIN]: 2,
  [ACCESS_LEVEL.ADMIN]: 3,
  [ACCESS_LEVEL.SUPER_ADMIN]: 4,
};
