import { useRoutes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "components/ErrorFallback";
import { LoaderComponent } from "components/Loader";
import routes from "router/router.config";
import { Suspense } from "react";

function App() {
  const elements = useRoutes(routes);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoaderComponent />}>{elements}</Suspense>
    </ErrorBoundary>
  );
}

export default App;
