import { ACCESS_LEVEL, USER_ROLES_BY_ACCESS_LEVEL } from "const";
import compact from "lodash/compact";
import { useOutletContext } from "react-router";
import { IOutletAuth, UserRoles } from "types";

export function checkRoleOnPermission({
  roles,
  requiredRole,
}: {
  roles?: IOutletAuth["roles"];
  requiredRole: string | UserRoles;
}) {
  if (!roles) {
    return false;
  }

  const requiredAccessLevel = getRootRoleAccessLevel([requiredRole]);
  const currentAccessLevel = getRootRoleAccessLevel(roles);
  return requiredAccessLevel <= currentAccessLevel;
}

export function getRootRoleAccessLevel(
  roles: string[] | IOutletAuth["roles"] = []
) {
  return compact(roles).reduce((result, role) => {
    //@ts-ignore
    const priority = USER_ROLES_BY_ACCESS_LEVEL[role];

    if (result < priority) {
      return priority;
    }
    return result;
  }, 0);
}

export function useCheckPremium() {
  const context = useOutletContext<IOutletAuth>();

  return checkRoleOnPermission({
    roles: context.roles,
    requiredRole: ACCESS_LEVEL.PREMIUM,
  });
}

export function useCheckIsSubscriptionAdmin() {
  const context = useOutletContext<IOutletAuth>();

  return checkRoleOnPermission({
    roles: context.roles,
    requiredRole: ACCESS_LEVEL.SUBSCRIPTION_ADMIN,
  });
}

export const useCheckIsAdmin = () => {
  const context = useOutletContext<IOutletAuth>();

  return checkRoleOnPermission({
    roles: context.roles,
    requiredRole: ACCESS_LEVEL.ADMIN,
  });
};

export const useCheckSuper = () => {
  const context = useOutletContext<IOutletAuth>();

  return checkRoleOnPermission({
    roles: context.roles,
    requiredRole: ACCESS_LEVEL.SUPER_ADMIN,
  });
};