import { createContext, useState, useCallback, ReactNode } from "react";
import { ConfirmationModal, ConfirmationModalConfig } from "components/ConfirmationModal/ConfirmationModal.component";

interface ConfirmationModalContextProps {
  setModalConfig: React.Dispatch<React.SetStateAction<Omit<ConfirmationModalConfig, "setOpen"> | null>>;
}

export const ConfirmationModalContext = createContext<ConfirmationModalContextProps | undefined>(undefined);

export const ConfirmationModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalConfig, setModalConfig] = useState<Omit<ConfirmationModalConfig, "setOpen"> | null>(null);

  const handleClose = () => {
    setModalConfig(null);
  }

  return (
    <ConfirmationModalContext.Provider value={{ setModalConfig }}>
      {children}
      {modalConfig && (
        <ConfirmationModal
          isOpen={modalConfig.isOpen}
          title={modalConfig.title}
          description={modalConfig.description}
          buttonText={modalConfig.buttonText}
          onApprove={() => {
            modalConfig.onApprove(modalConfig.params);
            handleClose();
          }}
          setOpen={() => handleClose()}
          hideCancelButton={modalConfig.hideCancelButton}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};