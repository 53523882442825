import axios from "axios";
import config from "config";
import { Analytics, Auth } from "aws-amplify";

export async function setupAmplifyAnalytics() {
    // get location info.
    const { raw, location } = await fetchLocation();
    const auth = await getSessionInfo();

    // setup analytics configuration.
    Analytics.configure({
        AWSPinpoint: {
            appId: config.ANALYTICS_APP_ID,
            region: config.REGION,
            mandatorySignIn: false,
            endpoint: JSON.parse(JSON.stringify({
                location,
                attributes: {
                    city: [raw.city],
                    country: [raw.country_name],
                },
                userAttributes: {
                    email: JSON.parse(JSON.stringify([auth.attributes?.email])),
                }
            })),
        }
    });

    // setup analytics session auto tracking.
    Analytics.autoTrack('session', {
        enable: true,
        provider: 'AWSPinpoint',
        attributes: async () => {
            if (typeof window === 'undefined') {
                return {};
            }

            const nav = window.navigator;
            if (!nav) {
                return {};
            }

            const tzMatch = /\(([A-Za-z\s].*)\)/.exec(new Date().toString());
            const timezone = tzMatch ? tzMatch[1] || '' : '';
            const { platform, language } = nav;

            return JSON.parse(JSON.stringify({
                platform,
                language,
                timezone,
            }));
        },
    });

    // setup analytics page view auto tracking.
    Analytics.autoTrack('pageView', {
        enable: true,
        eventName: 'pageView',
        type: 'SPA',
        provider: 'AWSPinpoint',
        getUrl: () => {
            // the default function
            return window.location.origin + window.location.pathname;
        }
    });
}

async function fetchLocation() {
    const raw = await (
        axios.get('https://ipapi.co/json/')
            .then(({ data }) => data)
            .catch(_ => { })
            .then(_ => (_ || {}))
    )

    if (Object.values(raw).length === 0) {
        return { raw, location: {} };
    }

    return {
        raw,
        location: {
            city: raw.city,
            country: raw.country,
            latitude: raw.latitude,
            longitude: raw.longitude,
        }
    }
}

async function getSessionInfo() {
    try {
        const auth = await Auth.currentAuthenticatedUser();

        return auth;
    } catch (_) {
        return {};
    }
}