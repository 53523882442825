import { isImageType } from "utils";
import * as yup from "yup";

import { MixedSchema } from "yup/lib/mixed";

/**
 * Check size of file that is tried to upload Check the size of the file that is being uploaded
 * @param fileSize - max file size in bytes
 */
export function checkFileSize(fileSize: number) {
  return (value: any) => {
    if (isImageType(value)) {
      return !!value.file && value.file?.size <= fileSize;
    }
    return true;
  };
}
/**
 *
 * @param cbs - array of cbs that pass to test method
 * @returns - yup schema
 */
export function mapTestRules(
  cbs: {
    cb: yup.TestFunction;
    id: string;
    message: string;
  }[]
): MixedSchema<any, Record<string, any>, any> {
  const result: MixedSchema<any, Record<string, any>, any> = yup.mixed();
  return cbs.reduce(
    (schema, { cb, id, message }) => schema.test(id, message, cb),
    result
  );
}

export function formatNumber(number: number) {
  return Math.abs(number) >= 1.0e9
    ? (Math.abs(number) / 1.0e9).toFixed(1) + "bn"
    : Math.abs(number) >= 1.0e6
    ? (Math.abs(number) / 1.0e6).toFixed(1) + "m"
    : Math.abs(number) >= 1.0e3
    ? (Math.abs(number) / 1.0e3).toFixed(1) + "k"
    : Math.abs(number);
}

export function formatToMillions(number: number) {
  return "$" + (Math.abs(number) / 1.0e6).toFixed(0) + "M";
}

export const adjustDateForUTC = (date: Date | string | number) => {
  const d = new Date(date);
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );
};

export const hasAtLeastOneFilledField = (obj: object) => {
  return Object.values(obj).some((el) => !!el);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
