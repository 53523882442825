import { Rings } from "react-loader-spinner";
import classNames from "classnames";
import { memo } from "react";

export function LoaderComponent({
  className,
  width,
}: {
  className?: string;
  width?: number;
}) {
  return (
    <div
      className={classNames(
        className,
        "w-full h-full flex justify-center items-center"
      )}
    >
      <Rings color="#00649C" height={100} width={width || 100} />
    </div>
  );
}

export default memo(LoaderComponent);
