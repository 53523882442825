export const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // file size in bytes
export const MAX_FILE_SIZE = 512 * 1024 * 1024; // file size in bytes
export const EMPLOYEE_RANGE = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10001+",
];

export const employeeRangeArray = [
  {
    id: 1,
    resultsCont: 1,
    tag: "1-10",
  },
  {
    id: 2,
    resultsCont: 1,
    tag: "11-50",
  },
  {
    id: 3,
    resultsCont: 1,
    tag: "51-100",
  },
  {
    id: 4,
    resultsCont: 1,
    tag: "101-500",
  },
  {
    id: 5,
    resultsCont: 1,
    tag: "501-1000",
  },
  {
    id: 6,
    resultsCont: 1,
    tag: "1001-5000",
  },
  {
    id: 7,
    resultsCont: 1,
    tag: "5001-10000",
  },
  {
    id: 8,
    resultsCont: 1,
    tag: "10001+",
  },
];
