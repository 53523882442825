import React, { memo } from "react";
import classnames from "classnames";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  onClick?: (params: any) => void;
  customPaddings?: boolean;
  deepCustomize?: boolean;
}

function Button({
  className,
  children,
  customPaddings,
  deepCustomize = false,
  ...rest
}: IProps) {
  const classes = classnames(
    className,
    {"flex outline-none focus:outline-none items-center text-sm": !deepCustomize},
    { "px-6 py-3": !customPaddings }
  );

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
}

export default memo(Button);
